




































import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({})
export default class ConfirmClaimDialog extends Vue {
  dialog = false
  resolve?: (any) => void

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    await promiseHelper.delay(100)
  }
}
